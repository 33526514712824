import styles from './styles.module.css';
import BannerCaja from '@/widgets/Banner/Templates/BannerCaja';
import BannerCajaGrande from '@/widgets/Banner/Templates/BannerCajaGrande';

const PISO4NOTASBarra = (props) => {
console.log(props);
    return (
          
        <div className={`${styles["Piso4Notas-parent"]}`}>
            <div className={`${styles['primer-bloque']}`}>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(1, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(2, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(3, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(4, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(5, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(6, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(7, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(8, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(9, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(10, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(11, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(12, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(13, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(14, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(15, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(16, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(17, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(18, "NotaContainerGrilla")}</div>
            </div>
            <div className={`${styles['segundo-bloque']}`}>
                <BannerCaja bannerId='caja3'/>
                <div className={styles['grid-item2']}>{props.renderContainerWidgetCallback(19, "Freehtml")}</div>
                <BannerCajaGrande bannerId='caja4'/>
            </div>                
        </div>
        
    )
}

export default PISO4NOTASBarra;
