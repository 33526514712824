import Image from "@/shared/components/Image/new"
import styles from "./styles.module.css";
import { loader } from "@/shared/functions/getImagesFunctions";
import { formatAuthorNames } from '@/shared/functions/formatAthorNames';
import { EnVivo } from "@/shared/components/enVivo";
import VideoLoop from "@/shared/components/VideoLoop";

const nextDomain = process.env.NEXT_PUBLIC_DOMAIN_URL
const defaultAuthorImg = `${nextDomain}/collections/utiles-diseno/generica___6288f834f96047001714ff34.jpg`;

const ModuloContainerOpinion = (props) => {
    const { title, author, summary, url, images, subtitle, hasBackground, metadata, croppedImages = [], videosOpenLoop = {}} = props;
    let authorsList = []
    if (author != undefined && author?.length) {
        const authorName = formatAuthorNames(author[0].name);
        author.length > 1 ? author.slice(0, 2).map(element => {
            
            const elementName = formatAuthorNames(element.name);
            return authorsList.push(element.slug ? `<a href=/autor/${element.slug}>${elementName}</a>` : elementName)
            
        }) 
        
        : authorsList.push(author[0].slug ? `<a href=/autor/${author[0].slug}>${authorName}</a>` : authorName)
    }
    const parsedAuthors = authorsList.join(' y ');
    
    const noTagsHtml = summary && summary.length && summary.replace(/<[^>]+>/g, '').replace(/&nbsp[;]?/ig, ' ');
    

    const MAIN_CROP = '3_2_300x200';
    const SECONDARY_CROP = '3_2_300x200';
    
   	const manualClippingMobile = croppedImages && croppedImages.filter(el => el.id === "Mobile")?.[0]


	const manualClippingMobileUrl = manualClippingMobile?.url ?? null;


	const manualClippingDesktop = croppedImages && croppedImages.filter(el => el.id === "Desktop")?.[0]
 	const defaultImage = `${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/img/default.png`
	const imageUrl = images?.[0] && `${manualClippingDesktop ? manualClippingDesktop?.url : images[0]?.url}`
	const imageTitle = images?.[0] && images[0]?.title
	let imagenMobile = SECONDARY_CROP; //uso el cropeo que genera lila por defecto
	if (manualClippingMobileUrl) {
		imagenMobile = manualClippingMobileUrl; //si hay recorte uso el recorte
	} 
    return (
        <>
            <a href={url} className={styles["nota-container"]}>
                <div className={`${styles["img-container"]}`}>
                    {
                        videosOpenLoop?.enabled
                        ? <VideoLoop videoLoop={videosOpenLoop}/>
                        : <Image
                            src={!imageUrl ? defaultImage : imageUrl}
                            alt={imageTitle}
                            clipping={MAIN_CROP}
                            width={300}
                            height={200}
                            className={styles['img']}
                            srcSet={{ mobile: imagenMobile }}
                          />
                    }
                     {metadata && metadata.typeWeb && metadata.typeWeb === "live_blog" && <EnVivo data-testid='envivo'/>}
                </div>
				    					
                {authorsList && (
                    <div className={`${styles["nota-container-text"]}`}>
                       
					   <div className={`${styles["img-opinion"]}`}>
                       {author && author.length ? (
		    					<a href={`/autor/${author[0]?.slug}`}>
		    						<Image
		    							src={`${nextDomain}/collections/authors/${author[0]?.imageID}`}
		    							alt={  "Imagen del Autor"}
		    							width={74}
		    							height={74}
		    							className={styles["img"]}
		    						/>
		    					</a>
		    				) : (
		    					<Image
		    						src={defaultAuthorImg}
		    						alt={ "Imagen del Autor"}
		    						width={74}
		    						height={74}
		    						className={styles["img"]}
		    					/>
		    				)}
                     {metadata && metadata.typeWeb && metadata.typeWeb === "live_blog" && <EnVivo data-testid='envivo'/>}
                </div>	
					   
					    {parsedAuthors && (
                <div
                    className={`${styles["nota-autor"]}`} 
                    dangerouslySetInnerHTML={{ 
                        __html: 'Por ' + parsedAuthors 
                    }} 
                />
            )}
						
						{metadata && metadata.sponsored  && <div className={`${styles["patrocinado"]}`}>patrocinado</div>}
                        <h2>
                        {subtitle != "" && (
                            <div 
                                className={`${styles["blue-txt"]}`} 
                                dangerouslySetInnerHTML={{ __html: subtitle }} 
                            />
                        )}

                        {title != "" && (
                            <div 
                                className={`${styles["nota-title"]}`} 
                                dangerouslySetInnerHTML={{ __html: title}} 
                            />
                        )}
                        
                        {summary != "" && (
                            <div className={
                                hasBackground
                                    ? `${styles["background"]} ${styles["nota-summary"]}`
                                    : `${styles["nota-summary"]}`}
                            >
                                {noTagsHtml}
                            </div>
                        )}
                     </h2>   
                    </div>
                )}
            </a>

            
        </>
    );
};
export default ModuloContainerOpinion;
