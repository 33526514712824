import styles from './styles.module.css';

const AperturaContainerDestacadoBullets = (props) => {
    return (
        <div className={`${styles["AperturaContainerDestacadoBullets-parent"]}`}> 
            <div className={`${styles['primer-bloque']}`}>
            <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(1, "DestacadaAperturaInContainersBuckets")}</div>
                <ul className={`${styles["ul-list"]}`}>
                    <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(5, "NotaAperturaBullet")}</div>
                    <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(6, "NotaAperturaBullet")}</div>
                    <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(7, "NotaAperturaBullet")}</div>
                </ul>
            </div>            
            <div className={`${styles['segundo-bloque']}`}>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(2, "NotaAperturaHorizontal")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(3, "NotaAperturaHorizontal")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(4, "NotaAperturaHorizontal2")}</div>
            </div>
            
        </div>
    )
}

export default AperturaContainerDestacadoBullets;
