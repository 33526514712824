import styles from './styles.module.css';

const OpinionContainer = (props) => {
const { title, link, title1, link1, title2, link2, title3, link3} = props;
console.log(props);
    return (
          
        <div>

  
                

        <div className={`${styles["pisoseccion-parent"]}`}>
        {title &&  <div className={`${styles["contenedor-gral-tittulo-container"]}`}>
                <div className={`${styles["contenedor-left-titulo"]}`}>
                    <h2><a href={link}>{title}</a></h2>
                </div>
            
            <div className={`${styles["scroll-margin"]}`}>
                <nav className={`${styles["menu-el-scroll"]}`}>
                <ul>
                    {link1 && title1  &&  <li><a href={link1} >{title1}</a></li>}
                    {link2 && title2  &&  <li><a href={link2} >{title2}</a></li>}
                    {link3 && title3  &&  <li><a href={link3} >{title3}</a></li>}                
                </ul>
                </nav>
            </div>
            </div> 
            }
            
            <div className={`${styles['primer-bloque']}`}>
                <div className={` ${styles["grid-item2"]}`}>{props.renderContainerWidgetCallback(1, "DestacadaContainerOpinion")}</div>
            </div>    
            <div className={`${styles['segundo-bloque']}`}>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(2, "ModuloContainerOpinion")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(3, "ModuloContainerOpinion")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(4, "ModuloContainerOpinion")}</div>
            </div>
            <div className={`${styles["contenedor-gral-tittulo-container1"]}`}>
            </div>
            <div className={`${styles['tercer-bloque']}`}>
                <div className={styles['grid-item3']}>{props.renderContainerWidgetCallback(5, "LoMasVisto")}</div>
            </div>                
        </div></div>
        
    )
}
export default OpinionContainer;
