import Image from "@/shared/components/Image/new"
import styles from "./styles.module.css";
import { loader } from "@/shared/functions/getImagesFunctions";
import { formatAuthorNames } from '@/shared/functions/formatAthorNames';
import { EnVivo } from "@/shared/components/enVivo";

const DestacadaAperturaInContainers = (props) => {
    const { title, author, summary, url, images, hasBackground, metadata, croppedImages = []} = props;
    let authorsList = []
    if (author != undefined && author?.length) {
        const authorName = formatAuthorNames(author[0].name);
        author.length > 1 ? author.slice(0, 2).map(element => {
            
            const elementName = formatAuthorNames(element.name);
            return authorsList.push(element.slug ? `<a href=/autor/${element.slug}>${elementName}</a>` : elementName)
            
        }) 
        
        : authorsList.push(author[0].slug ? `<a href=/autor/${author[0].slug}>${authorName}</a>` : authorName)
    }
    const parsedAuthors = authorsList.join(' - ');
    
    const noTagsHtml = summary && summary.length && summary.replace(/<[^>]+>/g, '').replace(/&nbsp[;]?/ig, ' ');
    

    const MAIN_CROP = '3_2_630x420';
    const SECONDARY_CROP = '3_2_300x200';
    
   	const manualClippingMobile = croppedImages && croppedImages.filter(el => el.id === "Mobile")?.[0]


	const manualClippingMobileUrl = manualClippingMobile?.url ?? null;


	const manualClippingDesktop = croppedImages && croppedImages.filter(el => el.id === "Desktop")?.[0]

	const defaultImage = `${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/img/default.png`
	const imageUrl = images?.[1] && `${manualClippingDesktop ? manualClippingDesktop?.url : images[1]?.url}`
	const imageTitle = images?.[1] && images[1]?.title

	let imagenMobile = SECONDARY_CROP; //uso el cropeo que genera lila por defecto
	if (manualClippingMobileUrl) {
		imagenMobile = manualClippingMobileUrl; //si hay recorte uso el recorte
	} 
    return (
        <>
            <a href={url} className={styles["destacada-container"]}>
                {authorsList && (
                    <div className={`${styles["destacada-container-text"]}`}>
                       
                    <div className={`${styles["img-container"]}`}>
                    <Image
                        src={!imageUrl ? defaultImage : imageUrl}
                        alt={imageTitle}
                        clipping={MAIN_CROP}
                        width={630}
                        height={420}
			srcSet={{mobile: imagenMobile}}
                        className={styles['img']}
                    />
                     {metadata && metadata.typeWeb && metadata.typeWeb === "live_blog" && <EnVivo data-testid='envivo'/>}
                </div>
                       
                        {title != "" && (
                            <h1 
                                className={`${styles["destacada-title"]}`} 
                                dangerouslySetInnerHTML={{ __html: title }} 
                            />
                            
                        )}
                        {summary != "" && (
                            <div className={
                                hasBackground
                                    ? `${styles["background"]} ${styles["destacada-summary"]}`
                                    : `${styles["destacada-summary"]}`}
                            >
                                {noTagsHtml}
                            </div>
                        )}
                                    {parsedAuthors && (
                <div
                    className={`${styles["destacada-autor"]}`} 
                    dangerouslySetInnerHTML={{ 
                        __html: 'Por ' + parsedAuthors 
                    }} 
                />
            )}
                    </div>
                )}
            
            


                </a>    
        </>
    );
};
export default DestacadaAperturaInContainers;
