import styles from './styles.module.css';

const AperturaContainerDestacado = (props) => {
    return (
        <div className={`${styles["AperturaContainerDestacado-parent"]}`}> 
            <div className={`${styles['primer-bloque']}`}>
            {props.renderContainerWidgetCallback(1, "DestacadaAperturaInContainers")}
            </div>            
            <div className={`${styles['segundo-bloque']}`}>
            {props.renderContainerWidgetCallback(2, "NotaContainerGrilla")}
            </div>
            <div className={`${styles['tercer-bloque']}`}>    
            {props.renderContainerWidgetCallback(3, "NotaContainerGrilla")}
            </div>    
            <div className={`${styles['cuarto-bloque']}`}>
            {props.renderContainerWidgetCallback(4, "NotaAperturaHorizontal2")}
            </div>
        </div>
            
       
    )
}

export default AperturaContainerDestacado;
